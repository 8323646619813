.container {
  width: 100%;
  height: 100%;
  padding: 30px;
  overflow-y: scroll;
  .title {
    margin: 20px 0;
    color: #38213a;
    font-weight: 500;
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  p {
    color: #a3a3a3;
    font-weight: 400;
    font-size: 0.9rem;
  }
  ul {
    color: #a3a3a3;
    font-weight: 400;
    font-size: 0.9rem;
  }
  .btndiv {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 35px 0 10px 0;
    button {
      padding: 10px 20px;
      font-size: 1.1rem;
      font-weight: 500;
      border: 1px solid red;
      cursor: pointer;
    }
  }
}
