.modalWrapperStyle {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modalBackdropStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(14, 18, 36, 0.2);
}

.modalStyle {
  display: flex;
  position: relative;
  flex-direction: column;
  color: rgb(177, 186, 211);
  background-color: #fff;
  height: 70vh;
  border-radius: 7px;
  width: 600px;
  z-index: 101;
  overflow-y: auto;
  padding: 9px;
}

.closeDivStyle {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.contentDivStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
