@import "../styles/breakpoints.scss";

.container {
  padding: 100px 0;
  background-color: #38213a;
  background-image: url("../images/trianglefooter.svg");
  background-repeat: no-repeat;
  background-position: bottom left;
  @include media("<=LGdesktop") {
    min-height: 100vh;
    padding: 0 0;
  }
  .wrapper {
    max-width: 1360px;
    margin: 0 auto;
    width: 100%;
    @include media("<=desktop") {
      padding: 0 80px;
    }
    @include media("<=tablet") {
      padding: 0 70px;
    }
    @include media("<=phoneXL") {
      padding: 0 50px;
    }
    @include media("<=phoneM") {
      padding: 0 30px;
    }
    .content {
      width: 100%;
      padding-top: 80px;
      color: #fff;
      .header {
        margin-bottom: 100px;
        @include media("<=phoneXL") {
          margin-bottom: 80px;
        }
        @include media("<=phoneM") {
          text-align: center;
        }
        img {
          max-width: 70%;
        }
      }
      .body {
        display: flex;
        @include media("<=desktop") {
          flex-direction: column;
        }
        .textWrapper {
          flex: 2;
          display: flex;
          flex-wrap: wrap;
          .text {
            width: 50%;
            margin-bottom: 50px;
            @include media("<=phoneXL") {
              width: 100%;
              margin-bottom: 50px;
            }
            &:nth-child(even) {
              @media only screen and (min-width: 768px) and (max-width: 990px) {
                padding-left: 30px;
              }
            }
            h2 {
              margin-bottom: 28px;
              line-height: 40px;
              font-size: 2rem;
              font-weight: 700;
              @include media("<=phoneXL") {
                font-size: 1.4rem;
                line-height: 30px;
              }
            }
            ul {
              li {
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                font-weight: 500;
                span {
                  font-size: 1rem;
                  @include media("<=phoneXL") {
                    font-size: 0.9rem;
                  }
                }
              }
            }
          }
        }
        .contacts {
          flex: 1;
          border-left: 1px solid rgba(0, 0, 0, 0.3);
          @include media("<=desktop") {
            border: none;
          }
          .contactwrapper {
            padding-top: 30px;
            @include media("<=desktop") {
              padding-bottom: 80px;
            }
            .socialmedia {
              display: flex;
              align-items: center;
              margin-bottom: 60px;
              margin-left: 80px;
              @include media("<=desktop") {
                margin-left: 0;
              }
              img {
                margin-right: 40px;
                @include media("<=phoneXL") {
                  margin-right: 30px;
                }
              }
              div {
                display: inline-block;
                width: 100%;
                max-width: 170px;
                height: 1px;
                background-color: #f74c47;
              }
            }
            .countrywrap {
              @include media("<=desktop") {
                display: flex;
              }
              @include media("<=tablet") {
                flex-direction: column;
              }
              .contactpt {
                color: #bfbfbf;
                margin-left: 80px;
                margin-bottom: 50px;
                @include media("<=desktop") {
                  margin-left: 0;
                  margin-right: 50px;
                  margin-bottom: 0;
                  width: 50%;
                }
                @include media("<=tablet") {
                  margin-right: 0;
                  width: 100%;
                  margin-bottom: 50px;
                }
                h3 {
                  font-weight: 500;
                  margin-bottom: 20px;
                  font-size: 2rem;
                  @include media("<=phoneXL") {
                    font-size: 1.4rem;
                  }
                }
                p {
                  font-size: 1rem;
                  line-height: 30px;
                  @include media("<=phoneXL") {
                    font-size: 0.9rem;
                    line-height: 25px;
                  }
                  &:nth-last-child(1) {
                    color: #f74c47;
                  }
                }
              }
              .contactbr {
                color: #bfbfbf;
                margin-left: 80px;
                @include media("<=desktop") {
                  margin-left: 0;
                  width: 50%;
                }
                @include media("<=tablet") {
                  width: 100%;
                }
                h3 {
                  font-weight: 500;
                  margin-bottom: 20px;
                  font-size: 2rem;
                  @include media("<=phoneXL") {
                    font-size: 1.4rem;
                  }
                }
                p {
                  font-size: 1rem;
                  line-height: 30px;
                  @include media("<=phoneXL") {
                    font-size: 0.9rem;
                    line-height: 25px;
                  }
                  &:nth-last-child(1) {
                    color: #f74c47;
                    @include media("<=phoneXL") {
                      word-break: break-all;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
