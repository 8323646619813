@import "../styles/breakpoints.scss";

.container {
  padding: 100px 0;
  width: 100%;
  background-image: url("../images/backgroundimg.svg"),
    url("../images/trianglebg.svg");
  background-repeat: no-repeat;
  background-size: auto 90%, auto auto;
  background-position-x: 10%, right;
  @include media("<=LGdesktop") {
    min-height: 100vh;
    padding: 0 0;
  }
  @include media("<=tablet") {
    background-image: url("../images/backgroundimg.svg");
    background-repeat: no-repeat;
    background-size: auto 90%;
    background-position-x: 10%;
  }
  .wrapper {
    max-width: 1360px;
    margin: 0 auto;
    width: 100%;
    @include media("<=desktop") {
      padding: 0 80px;
    }
    @include media("<=tablet") {
      padding: 0 70px;
    }
    @include media("<=phoneXL") {
      padding: 0 50px;
    }
    @include media("<=phoneM") {
      padding: 0 30px;
    }
    .header {
      padding-top: 80px;
      @include media("<=phoneM") {
        text-align: center;
      }
      img {
        max-width: 70%;
      }
    }
    .content {
      display: flex;
      width: 100%;
      @include media("<=tabletL") {
        flex-direction: column;
      }
      .contentWrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        padding-right: 50px;
        @include media("<=tabletL") {
          width: 100%;
          padding: 0;
          margin: 100px 0 60px 0;
        }
        @include media("<=phoneXL") {
          margin: 80px 0 60px 0;
        }
        .text {
          line-height: 30px;
          color: #38213a;
          h1 {
            margin-bottom: 50px;
            font-size: 2.1875;
            @include media("<=phoneXL") {
              font-size: 1.4rem;
            }
          }
          p {
            width: 70%;
            @include media("<=tabletL") {
              width: 100%;
            }
            @include media("<=phoneXL") {
              font-size: 0.9rem;
              line-height: 25px;
            }
          }
          .firstp {
            margin-bottom: 45px;
            font-size: 1rem;
            line-height: 30px;
            @include media("<=phoneXL") {
              font-size: 0.9rem;
              line-height: 25px;
              margin-bottom: 30px;
            }
          }
          .secondp {
            margin-bottom: 16px;
            @include media("<=phoneXL") {
              margin-bottom: 10px;
              font-size: 0.9rem;
              line-height: 25px;
            }
          }
        }
      }
      .form {
        width: 50%;
        color: #a3a3a3;
        font-size: 1rem;
        margin-bottom: 80px;
        @include media("<=tabletL") {
          width: 100%;
        }
        form {
          display: flex;
          flex-direction: column;
          box-shadow: 0px 15px 50px #9f9f9f29;
          padding: 50px;
          background-color: white;
          @include media("<=phone") {
            padding: 35px;
          }
          input {
            border: none;
            border-bottom: 1px solid #a3a3a3;
            padding-bottom: 8px;
            margin-bottom: 30px;
            &:nth-last-child(1) {
              margin: 0;
            }
            &::placeholder {
              color: #a3a3a3;
              font-weight: 500;
              font-family: Montserrat, sans-serif;
              font-size: 1rem;
              @include media("<=phoneXL") {
                font-size: 0.9rem;
              }
            }
            &:focus {
              outline: none;
            }
          }
          .inline {
            display: flex;
            width: 100%;
            @include media("<=desktopS") {
              flex-direction: column;
            }
            @include media("<=tabletL") {
              flex-direction: row;
            }
            @include media("<=phoneXL") {
              flex-direction: column;
            }
            input {
              flex: 1;
              margin-bottom: 30px;
              margin-right: 20px;
              @include media("<=desktopS") {
                margin-right: 0;
              }
              @include media("<=tabletL") {
                margin-right: 20px;
              }
              @include media("<=phoneXL") {
                margin-right: 0;
              }
              &:nth-last-child(1) {
                margin-right: 0;
              }
            }
          }
          .msgtext {
            h5 {
              font-weight: 500;
              margin-bottom: 5px;
              font-size: 1rem;
              @include media("<=phoneXL") {
                font-size: 0.9rem;
              }
            }
            p {
              font-weight: 300;
              color: #a3a3a3;
              margin-bottom: 10px;
              font-size: 1rem;
              @include media("<=phoneXL") {
                font-size: 0.9rem;
              }
            }
          }
          textarea {
            border: 0.5px solid #a3a3a3;
            resize: none;
            height: 100px;
            padding: 15px;
            &:focus {
              outline: none;
            }
          }
          .check {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 25px 0 35px 0;
            @include media("<=phoneXL") {
              font-size: 0.9rem;
              flex-direction: column;
            }
            label {
              display: flex;
              align-items: center;
              cursor: pointer;
              margin-right: 10px;
              @include media("<=phoneXL") {
                margin-bottom: 12px;
                margin-right: 0;
              }
              input {
                margin: 0;
                height: 23px;
                width: 23px;
                cursor: pointer;
                @include media("<=phoneXL") {
                  height: 16px;
                  width: 16px;
                }
              }
              span {
                font-weight: 500;
                margin-left: 10px;
                font-size: 1rem;
                @include media("<=phoneXL") {
                  font-size: 0.9rem;
                  margin-left: 25px;
                }
              }
            }
            .btntos {
              @include media("<=phoneXL") {
                text-align: center;
              }
              &:hover {
                color: red;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
          .btncontainer {
            display: flex;
            justify-content: center;
            button {
              padding: 20px 25px;
              color: #f74c47;
              border: 1px solid #f74c47;
              background-color: transparent;
              cursor: pointer;
              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }
  }

  button.mProgress {
    $_m_progress_w: 36px;
    pointer-events: none;
    position: relative;
    opacity: 0.8;
    color: transparent !important;
    text-shadow: none !important;
    &:hover,
    &:active,
    &:focus {
      cursor: default;
      color: transparent;
      outline: none !important;
      box-shadow: none;
    }
    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      background: transparent;
      border: 4px solid #f74c47;
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      margin-top: -($_m_progress_w/2) - 4;
      margin-left: -($_m_progress_w/2);
      width: $_m_progress_w;
      height: $_m_progress_w;
      animation: ld 1s ease-in-out infinite;
    }
  }
}

@keyframes ld {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1.1);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}
